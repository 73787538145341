import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

const url = "api/v1/waystar";

export async function checkWaystar() {
  return await ApiService.post(url + "/checkWaystar", {}).then((res) => {
    const items = res.data;
    return items;
  });
}

export async function saveWaystar() {
  return await ApiService.post(url + "/saveWaystar", {}).then((res) => {
    const items = res.data;
    return items;
  }).catch(({ response }) => {
    processErrors(response);
  });;
}

