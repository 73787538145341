import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/billingEvent";

export async function getBillingEventsClaims(claimId) {
  const request = {
    claimId: claimId,
    keyword: "",
    pageNumber: 0,
    pageSize: 100,
    orderBy: ["id"],
  };
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const items = res.data.data;
      //TODO DEBT
      items.forEach((item: any) => {
        if(item.text)
          item.claimDispositionRecord = JSON.parse(item.text);
      });

      return items;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function getBatchResponces(claimId) {
  const request = {
    claimId: claimId,
  };
  return await ApiService.post(url + "/getList", request as never)
    .then((res) => {
      const items = res.data;
      return items;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}
